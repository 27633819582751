<template>
    <div class="padding24 color_white" id="third_Party_order">
        <a-spin :spinning="spinning" size="large" :tip="tip">
            <div class="isDisplay" v-show="$route.meta.isShow">
                <div class="top">
                    <a-form layout="inline">
                        <a-form-item>
                            <a-button icon="plus" class="margin_right20" type="primary" v-if="isShowAdd" @click="addClick">添加</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <!-- 表格 -->
                <a-table :columns="columns" :data-source="tableData" :pagination="false" :row-key="record => record.id"  :loading="tableLoading">
                    <span slot="operation" slot-scope="operation, record">
                        <a id="copy_memberNo" v-if="isShowEdit" class="margin_right10" type="primary" @click="editClick(record)">编辑</a>
                        <template v-if="isShowDelete">
                            <a-popconfirm title="确认要删除此分类吗？" ok-text="确定" cancel-text="取消" @confirm="deleteClick(record)">
                                <a id="copy_memberNo" type="primary">删除</a>
                            </a-popconfirm>
                        </template>
                    </span>
                </a-table>
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
                <!-- 返回顶部 -->
                <a-back-top :target="targetFn" :visibilityHeight="100"/>
            </div>
        </a-spin>

        <!--添加分类-->
        <a-modal v-model="visible" title="添加分类" @ok="handleOk">
            <a-form-model v-if="visible" ref="ruleForm" :model="form" :rules="rules" >
                <a-form-model-item ref="name" label="分类名称" prop="name">
                    <a-input v-model="form.name" placeholder="请输入分类名称" />
                </a-form-model-item>
                <a-form-model-item ref="sort" label="排序" prop="sort">
                    <a-input-number v-model="form.sort" placeholder="请输入排序" style="width: 100%"/>
                </a-form-model-item>
            </a-form-model>
        </a-modal>

        <router-view />
    </div>
</template>

<script>
import moment from "moment";
import {AddFreeVideoSort, EditFreeVideoSort,DeleteFreeVideoSort,FreeVideoSortList } from "@/request/api/videoManage";
import MyPagination from "@/components/pagination/MyPagination";
import {message,popconfirm} from "ant-design-vue";
import {codeFn} from "@/utils/tools";
import {AddTopicCategory} from "@/request/api/circleManage";

export default {
    components: {MyPagination},
    created() {
        this.getInitFn();
        if (codeFn("/adminv2/addFreeVideoSort")) this.isShowAdd = true;
        if (codeFn("/adminv2/editFreeVideoSort")) this.isShowEdit = true;
        if (codeFn("/adminv2/deleteFreeVideoSort")) this.isShowDelete = true;
    },
    data() {
        return {
            visible:false,

            isShowAdd:false,
            isShowEdit:false,
            isShowDelete:false,

            isShowAddOrEdit:false,
            isShowStatus:false,

            tip: "",
            tableLoading: true,
            spinning: false, // 全局loading
            pageNo: 1,
            pageSize: 20,
            count: 0,

            form:{
                id:'',
                name:'',
                sort:'',
            },

            rules: {
                name: [
                    { required: true, message: "请输入话题名称", trigger: "blur" },
                    { max: 45, message: "最多输入45个字符", trigger: "change" },
                ],
                sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
            },

            columns: [
                {
                    title: "话题名称",
                    dataIndex: "name",
                    key: "name",
                },
                {
                    title: "排序",
                    dataIndex: "sort",
                    key: "sort",
                },
                {
                    title: "操作",
                    fixed: "right",
                    width: 120,
                    key: "operation",
                    dataIndex: "operation",
                    scopedSlots: {customRender: "operation"},
                },
            ],
            tableData: [],
        };
    },

    watch: {
        $route(to) {
            if (to.path === "/circleManage/talkList") {
                this.getInitFn();
            }
        },
    },

    methods: {
        moment,

        // 点击添加按钮
        addClick() {
            this.visible = true
            this.form.name = ''
            this.form.sort = ''
            this.form.id = ''
        },

        //分类确定按钮
        handleOk(){
            let self = this
            self.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    if(!self.form.id){
                        AddFreeVideoSort({
                            name:self.form.name,
                            sort:self.form.sort
                        }).then(({ code, data }) => {
                            if(code == 200){
                                self.getInitFn()
                                self.visible = false
                            }else{
                                message.success(data.msg)
                            }
                        });
                    }else{
                        EditFreeVideoSort({
                            id:self.form.id,
                            name:self.form.name,
                            sort:self.form.sort
                        }).then(({ code, data }) => {
                            if(code == 200){
                                self.getInitFn()
                                self.visible = false
                            }else{
                                message.success(data.msg)
                            }
                        });
                    }
                }
            });
        },

        // 点击编辑按钮
        editClick(record) {
            this.visible = true
            this.form.name = record.name
            this.form.sort = record.sort
            this.form.id = record.id
        },

        // 点击删除按钮
        deleteClick(record) {
            DeleteFreeVideoSort({
                id:record.id,
            }).then(({ code, data }) => {
                if(code == 200){
                    message.success('删除成功！')
                    this.getInitFn()
                }else{
                    message.success(data.msg)
                }
            });
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getInitFn();
        },

        // 原属数据获取
        getInitFn() {
            this.tableLoading = true
            FreeVideoSortList({
                pageSize:this.pageSize,
                pageNo:this.pageNo
            }).then(({ code, data }) => {
                this.tableLoading = false
                this.tableData = data.list;
                this.count = data.count
            });
        },

        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#third_Party_order");
        },
    },
};
</script>

<style lang="less" scoped>
#third_Party_order {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.top {
    .top_line {
        margin-bottom: 20px;
        display: flex;

        span {
            margin-right: 20px;

            /deep/ .ant-input {
                width: 220px;
            }

            /deep/ .ant-select-selection {
                width: 110px;
                margin-left: 10px;
            }

            /deep/ .ant-calendar-picker-input {
                margin-left: 10px;
                width: auto;
            }

            /deep/ .ant-calendar-picker {
                width: 400px !important;
            }
        }

        .ant-btn {
            margin-right: 20px;
        }
    }
}
</style>
